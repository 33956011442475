.Pledgepic {
  width: 170px;
  height: 170px;
  border-radius: 20px;
}

.picline {
  display: flex;
  justify-content: center;
}

.allplans {
  display: flex;
  justify-content: center;
}

@keyframes flash {
  from {
    background: linear-gradient(#4651eb, #57e985);
  }
  to {
    background: linear-gradient(#d65c5c, #f54af5);
  }
}

.suggestionname,
.suggestiontext {
  color: transparent;
  background-clip: text;
}
.suggestiontext {
  background-image: linear-gradient(rgb(85, 0, 255), rgb(233, 68, 68));
  font-weight: 300;
}

.suggestionname {
  background-image: linear-gradient(rgb(255, 0, 200), rgb(200, 233, 68));
}
