/* color: transparent;
  background-clip: text;
}
.suggestiontext {
  background-image: linear-gradient(rgb(85, 0, 255), rgb(233, 68, 68));
  font-weight: 300;
}
*/

.paydemand {
  background: linear-gradient(#dff071, #fa8ef3);
}
